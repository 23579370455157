import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged, distinctUntilKeyChanged, map, skip } from 'rxjs';
import { Article } from '../models/article';
import { Page } from '../models/page';
import { AppState } from '../models/appState';
import { StorageService } from './storage.service';
import initialArticleData from '../initial-data/article.data';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './language.service';
import { environment } from '../../environments/environment';
import { MedicalTrail } from 'src/app/models/medical-trail';

export const APP_STATE_KEY = 'app-state-v2';
export const DEFAULT_TREATMENT_PLACE = 'andet';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor(
    private storageService: StorageService,
    private apiService: ApiService,
    private translate: TranslateService,
    private languageService: LanguageService,
  ) {}

  init() {
    return new Promise((resolve) => {
      this.storageService.get$(APP_STATE_KEY).subscribe((storageState) => {
        if (storageState) {
          this.setState(storageState);
        } else {
          this.setCurrentLanguage(this.languageService.getSystemLanguage());
        }
      });
      // skip the first initialization of the state - after that always save the state to storage
      this.state$.pipe(skip(1)).subscribe((state) => {
        this.storageService.set$(APP_STATE_KEY, state);
      });
      // fetch the content from the API
      this.apiService.getAppContent$().subscribe((value) => this.setState({ ...this.state.getValue(), ...value }));
      // set the language for the translation service
      this.currentLanguage$.subscribe((language) => {
        if (language) {
          this.translate.use(language);
        }
      });
      if (environment.env === 'development') {
        // log the state
        this.state$.subscribe((state) => console.log(state)); // eslint-disable-line no-console
      }
      resolve(true);
    });
  }

  private state = new BehaviorSubject<AppState>({
    ...initialArticleData,
    treatmentPlace: DEFAULT_TREATMENT_PLACE,
    currentLanguage: this.languageService.getSystemLanguage(),
    medicalTrail: 'not-enrolled',
  });

  readonly state$ = this.state.asObservable();
  readonly articles$: Observable<Array<Article>> = this.state.asObservable().pipe(
    map((state) => state.articles.filter((article) => this.getCurrentLanguage() === article.language)),
    distinctUntilChanged(),
  );
  readonly secretArticles$: Observable<Array<Article>> = this.state.asObservable().pipe(
    map((state) =>
      state.articles.filter(
        (article) =>
          'secret-' + this.getCurrentLanguage() === article.secretLanguage &&
          (this.getMedicalTrail()?.includes('enrolled-with-video') ||
            this.getMedicalTrail()?.includes('testing-with-video')),
      ),
    ),
    distinctUntilChanged(),
  );
  readonly pages$: Observable<Array<Page>> = this.state.asObservable().pipe(
    map((state) => state.pages.filter((page) => this.getCurrentLanguage() === page.language)),
    distinctUntilChanged(),
  );
  readonly frontPageIntro$: Observable<string> = this.state.asObservable().pipe(
    map((state) => {
      const frontPageIntro1 = state.frontPageIntros.find(
        (frontPageIntro) => this.getCurrentLanguage() === frontPageIntro.language,
      );
      return frontPageIntro1?.content || '';
    }),
    distinctUntilChanged(),
  );
  readonly currentLanguage$: Observable<string | undefined> = this.state.asObservable().pipe(
    distinctUntilKeyChanged('currentLanguage'),
    map((state) => state.currentLanguage),
  );
  readonly currentTreatmentPlace$: Observable<string | undefined> = this.state.asObservable().pipe(
    distinctUntilKeyChanged('treatmentPlace'),
    map((state) => state.treatmentPlace),
  );
  readonly medicalTrail$: Observable<MedicalTrail | undefined> = this.state.asObservable().pipe(
    distinctUntilKeyChanged('medicalTrail'),
    map((state) => state.medicalTrail),
  );

  setCurrentLanguage(currentLanguage: string): void {
    this.state.next({ ...this.state.getValue(), currentLanguage });
  }

  getCurrentLanguage(): string | undefined {
    return this.state.getValue().currentLanguage;
  }

  setCurrentTreatmentPlace(treatmentPlace: string): void {
    this.state.next({ ...this.state.getValue(), treatmentPlace });
  }

  getCurrentTreatmentPlace(): string | undefined {
    return this.state.getValue().treatmentPlace;
  }

  setArticles(articles: Article[]) {
    this.state.next({ ...this.state.getValue(), articles });
  }

  getArticle(slug: string): Article | undefined {
    return this.state.getValue().articles.find((article) => article.slug === slug);
  }

  getExtraPage(slug: string): Article | undefined {
    return this.state.getValue().pages.find((page) => page.slug === slug);
  }

  getFrontPageIntro(language: string): string | undefined {
    return this.state.getValue().frontPageIntros.find((intro) => intro.language === language)?.content;
  }

  setState(state: AppState): void {
    const newest = state.articles.reduce((prev, current) => (prev.lastupdated > current.lastupdated ? prev : current));
    const older = this.state
      .getValue()
      .articles.reduce((prev, current) => (prev.lastupdated > current.lastupdated ? prev : current), {
        lastupdated: 0,
      });
    if (newest.lastupdated > older.lastupdated) {
      this.state.next({ ...this.state.getValue(), ...state });
    } else {
      this.state.next({
        ...this.state.getValue(),
        treatmentPlace: state.treatmentPlace,
        currentLanguage: state.currentLanguage,
        medicalTrail: state.medicalTrail,
      });
    }
  }

  setMedicalTrail(medicalTrail: MedicalTrail): void {
    this.state.next({ ...this.state.getValue(), medicalTrail });
  }

  getMedicalTrail(): MedicalTrail | undefined {
    return this.state.getValue().medicalTrail;
  }
}
