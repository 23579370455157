import { Routes } from '@angular/router';
import { RouteEnum } from './models/route.enum';
import { ArticlePageComponent } from './pages/article/article-page.component';
import { ExtraPageComponent } from './pages/extra/extra-page.component';
import HomePageComponent from './pages/home/home-page.component';
import { SettingsPageComponent } from './pages/settings/settings-page.component';

export const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    data: { title: RouteEnum.Home },
  },
  {
    path: 'article/:slug',
    component: ArticlePageComponent,
    data: { title: RouteEnum.Article },
  },
  {
    path: 'page/:slug',
    component: ExtraPageComponent,
    data: { title: RouteEnum.Page },
  },
  {
    path: 'settings',
    component: SettingsPageComponent,
    data: { title: RouteEnum.Settings },
  },
  { path: '**', redirectTo: '' },
];
