<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button text="" [routerLink]="'/settings'"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'SETTINGS.MEDICAL_TRAIL.TITLE' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-text *ngIf="medicalTrail !== 'not-enrolled'" class="page-content">
    <h2>{{ 'SETTINGS.MEDICAL_TRAIL.ENROLLED_TITLE' | translate }}</h2>
    <p>{{ 'SETTINGS.MEDICAL_TRAIL.ENROLLED_DESCRIPTION' | translate }}</p>
  </ion-text>
  <form *ngIf="medicalTrail === 'not-enrolled'" [formGroup]="ionicForm" (ngSubmit)="submitForm()" novalidate>
    <!-- create a input form that can accept a code -->
    <ion-row>
      <ion-col>
        <p>{{ 'SETTINGS.MEDICAL_TRAIL.JOIN_DESCRIPTION' | translate }}</p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-input
          type="password"
          formControlName="code"
          [clearInput]="true"
          errorText="{{ 'SETTINGS.MEDICAL_TRAIL.INVALID_CODE' | translate }}"
          placeholder="{{ 'SETTINGS.MEDICAL_TRAIL.YOUR_CODE' | translate }}"
          required
          label="{{ 'SETTINGS.MEDICAL_TRAIL.INPUT_CODE' | translate }}"
          labelPlacement="stacked"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button expand="block" type="submit">{{ 'SETTINGS.MEDICAL_TRAIL.BUTTON_JOIN' | translate }}</ion-button>
      </ion-col>
    </ion-row>
  </form>
</ion-content>
