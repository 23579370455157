import { Component } from '@angular/core';
import { SettingsHomeComponent } from './settings-home/settings-home.component';
import { IonNav } from '@ionic/angular/standalone';

@Component({
  selector: 'app-settings',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
  standalone: true,
  imports: [IonNav],
})
export class SettingsPageComponent {
  root = SettingsHomeComponent;
}
