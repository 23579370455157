<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button text="" [routerLink]="'/settings'"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'SETTINGS.LANGUAGE' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-radio-group (ionChange)="setLanguage($event)" [value]="currentLanguage">
      <ion-item>
        <ion-radio justify="start" labelPlacement="end" value="en">{{ 'SETTINGS.EN' | translate }}</ion-radio>
      </ion-item>
      <ion-item>
        <ion-radio justify="start" labelPlacement="end" value="da">{{ 'SETTINGS.DA' | translate }}</ion-radio>
      </ion-item>
      <ion-item>
        <ion-radio justify="start" labelPlacement="end" value="au">{{ 'SETTINGS.AU' | translate }}</ion-radio>
      </ion-item>
      <ion-item>
        <ion-radio justify="start" labelPlacement="end" value="sv">{{ 'SETTINGS.SV' | translate }}</ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>
