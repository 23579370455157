import { Injectable } from '@angular/core';
import { RateApp } from 'capacitor-rate-app';
import { Platform } from '@ionic/angular/standalone';
import { FirebaseAnalyticsService } from './firebase-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class RateService {
  constructor(
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private platform: Platform,
  ) {}

  init() {
    setTimeout(async () => {
      await this.platform.ready();
      await RateApp.requestReview();
      const platforms = this.platform.platforms().join('-');
      this.firebaseAnalyticsService.trackEvent('rating_popup', { platforms });
    }, 10 * 1000);
  }
}
