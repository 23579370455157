import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StateService } from 'src/app/services/state.service';
import { MedicalTrail } from 'src/app/models/medical-trail';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FirebaseAnalyticsService } from '../../../services/firebase-analytics.service';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonRouterLink,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-settings-medical-trail',
  templateUrl: './settings-medical-trail.component.html',
  styleUrls: ['./settings-medical-trail.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonText,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonRouterLink,
  ],
})
export class SettingsMedicalTrailComponent implements OnInit {
  medicalTrail: MedicalTrail | undefined = undefined;
  code = '';
  ionicForm: FormGroup;

  constructor(
    private stateService: StateService,
    public formBuilder: FormBuilder,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
  ) {
    this.ionicForm = this.formBuilder.group({
      code: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.stateService.medicalTrail$.subscribe((medicalTrail) => {
      this.medicalTrail = medicalTrail;
    });
  }

  getGroup(code: string): MedicalTrail {
    code = code?.trim()?.toLowerCase();
    switch (code) {
      case 'svane1pumpe':
        return 'enrolled-with-video-1-pump';
      case 'svane1pen':
        return 'enrolled-with-video-1-pen';
      case 'svane2':
        return 'enrolled-with-video-2';
      case 'due1pumpe':
        return 'enrolled-without-video-1-pump';
      case 'due1pen':
        return 'enrolled-without-video-1-pen';
      case 'due2':
        return 'enrolled-without-video-2';
      case 'testsvane':
        return 'testing-with-video';
      case 'testdue':
        return 'testing-without-video';
      default:
        return 'not-enrolled';
    }
  }

  submitForm() {
    if (!this.ionicForm?.valid) {
      this.ionicForm?.controls.code.markAsTouched();
      return false;
    }
    const group = this.getGroup(this.ionicForm.value.code);
    if (group === 'not-enrolled') {
      this.ionicForm.controls.code.setErrors({ invalid: true });
      return false;
    }
    this.stateService.setMedicalTrail(group);
    this.firebaseAnalyticsService.setUserProperty('medicalTrail', group);
    this.firebaseAnalyticsService.trackEvent('medical_trail', { medicalTrail: group });
    return true;
  }
}
