import { Injectable } from '@angular/core';
import { StateService } from './state.service';
import { RouteEnum } from '../models/route.enum';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, Observable, of } from 'rxjs';
import { SeoSocialShareData } from 'ngx-seo';

const descriptionFormatter = (description: string) => {
  if (!description) {
    return '';
  }
  description = description.replace(/<\/?[^>]+(>|$)/g, '');
  return description.length < 160 ? description : `${description.substring(0, description.indexOf(' ', 160))}`;
};

@Injectable({
  providedIn: 'root',
})
export class SeoContentService {
  constructor(
    private stateService: StateService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
  ) {}

  async getTitle(route: RouteEnum, url: string): Promise<SeoSocialShareData> {
    // this is needed to make sure we get the resolved language
    await firstValueFrom(this.translateService.get('MENU.HOME'));
    let $title: Observable<string>;
    let description = '';
    const image = this.translateService.currentLang.match(/da|sv/)
      ? 'assets/images/logo-gravidmeddiabetes.png'
      : 'assets/images/logo-pregnantwithdiabetes.png';
    switch (route) {
      case RouteEnum.Home:
        $title = this.translateService.get('MENU.HOME');
        description = descriptionFormatter(
          this.stateService.getFrontPageIntro(this.translateService.currentLang) || '',
        );
        break;
      case RouteEnum.Settings:
        $title = this.translateService.get('MENU.SETTINGS');
        break;
      case RouteEnum.Page:
        const page = this.stateService.getExtraPage(this.route.firstChild?.snapshot.paramMap.get('slug') || '');
        $title = of(page?.menutitle || 'Diabetes');
        description = descriptionFormatter(page?.content || '');
        break;
      case RouteEnum.Article:
        const article = this.stateService.getArticle(this.route.firstChild?.snapshot.paramMap.get('slug') || '');
        $title = of(article?.menutitle || 'Diabetes');
        description = descriptionFormatter(article?.content || '');
        break;
      default:
        $title = of('Diabetes');
        break;
    }

    const title = await firstValueFrom($title);
    const postTile = await firstValueFrom(this.translateService.get('APP.TITLE'));
    return {
      title: `${title} - ${postTile}`,
      description,
      image,
      url,
    };
  }
}
