import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { from, Observable, map } from 'rxjs';
import { combinedCheckerHelper } from '../helpers/storage-checker.helper';
import { captureException } from '../helpers/error-capture.helper';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {}

  async init() {
    try {
      if (!combinedCheckerHelper()) {
        return;
      }
      this._storage = await this.storage.create();
    } catch (error) {
      captureException(error, 'info', 'storage-service-init');
    }
  }

  set$(key: string, value: any): Observable<any> {
    if (!this._storage) {
      return from(Promise.resolve(undefined));
    }
    return from(this._storage?.set(key, JSON.stringify(value)));
  }

  get$(key: string): Observable<any> {
    if (!this._storage) {
      return from(Promise.resolve(undefined));
    }
    return from(this._storage.get(key)).pipe(map((value: any) => (value ? JSON.parse(value) : undefined)));
  }
}
