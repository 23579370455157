import * as Sentry from '@sentry/capacitor';
import { SeverityLevel } from '@sentry/capacitor';

export const captureException = (error: any, level: SeverityLevel, tag?: string) => {
  Sentry.withScope(function (scope) {
    scope.setLevel('info');
    tag && scope.setTag('internal-capture', tag);
    scope.setExtra('name', error.name);
    scope.setExtra('message', error.message);
    scope.setExtra('stack', error.stack);
    Sentry.captureException(error, () => scope);
  });
};
