import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of, retry, catchError } from 'rxjs';
import { AppState } from '../models/appState';
import initialArticleData from '../initial-data/article.data';
import { captureException } from '../helpers/error-capture.helper';

function extractHttpModuleError(error: HttpErrorResponse): string | Error {
  // The `error` property of http exception can be either an `Error` object, which we can use directly...
  if (isErrorOrErrorLikeObject(error.error)) {
    return error.error;
  }

  // ... or an`ErrorEvent`, which can provide us with the message but no stack...
  if (error.error instanceof ErrorEvent && error.error?.message) {
    return error.error.message;
  }

  // ...or the request body itself, which we can use as a message instead.
  if (typeof error.error === 'string') {
    return `Server returned code ${error.status} with body "${error.error}"`;
  }

  // If we don't have any detailed information, fallback to the request message itself.
  return error.message;
}

function isErrorOrErrorLikeObject(value: unknown): value is Error {
  if (value instanceof Error) {
    return true;
  }

  if (value === null || typeof value !== 'object') {
    return false;
  }

  const candidate = value as any;

  return (
    isString(candidate.name) &&
    isString(candidate.name) &&
    isString(candidate.message) &&
    (undefined === candidate.stack || isString(candidate.stack))
  );
}

function isString(value: unknown): value is string {
  return typeof value === 'string';
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  getAppContent$(): Observable<AppState> {
    try {
      return this.httpClient
        .get<AppState>(environment.appApi, {
          headers: new HttpHeaders({
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
          }),
        })
        .pipe(
          retry({ count: 3, delay: 1000 }),
          catchError((error) => {
            captureException(extractHttpModuleError(error), 'info', 'retry-getAppContent');
            return of(initialArticleData);
          }),
        );
    } catch (error: any) {
      captureException(extractHttpModuleError(error), 'info', 'getAppContent');
      return of(initialArticleData);
    }
  }
}
