<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>{{ 'SETTINGS.SETTINGS' | translate }}</ion-title>
    <ion-buttons slot="end">
      <i class="icon-settings header-icon"></i>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item button (click)="selectLanguage()" detail="true">
      <ion-label>
        <h2>{{ 'SETTINGS.LANGUAGE' | translate }}</h2>
        <p>{{ 'SETTINGS.' + currentLanguage | uppercase | translate }}</p>
      </ion-label>
    </ion-item>
    <ion-item *ngIf="currentLanguage === 'da'" button (click)="selectTreatmentPlace()" detail="true">
      <ion-label>
        <h2>{{ 'SETTINGS.TREATMENT_PLACE' | translate }}</h2>
        <p>{{ 'SETTINGS.' + currentTreatmentPlace | uppercase | translate }}</p>
      </ion-label>
    </ion-item>
    <ion-item
      *ngIf="currentLanguage === 'da' || currentLanguage === 'en'"
      button
      (click)="selectMedicalTrail()"
      detail="true"
    >
      <ion-label>
        <h2>{{ 'SETTINGS.MEDICAL_TRAIL.TITLE' | translate }}</h2>
        <p>{{ 'SETTINGS.MEDICAL_TRAIL.' + medicalTrail | translate }}</p>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
