<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list class="ion-no-border">
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerDirection]="'back'" [routerLink]="['']">
              <i class=""></i>
              <ion-label>
                {{ 'MENU.HOME' | translate }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" *ngFor="let article of articles$ | async">
            <ion-item [routerDirection]="'forward'" [routerLink]="['/article/' + article.slug]">
              <i class="{{ article.icon }} menu-icon"></i>
              <ion-label>
                {{ article.menutitle }}
                <i *ngIf="article.hasFilm && medicalTrail?.includes('with-video')" class="icon-play film-icon"></i>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerDirection]="'forward'" [routerLink]="['/settings']">
              <i class="icon-settings menu-icon"></i>
              <ion-label>
                {{ 'MENU.SETTINGS' | translate }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" *ngFor="let page of pages$ | async">
            <ion-item [routerDirection]="'forward'" [routerLink]="['/page/' + page.slug]">
              <i class="{{ page.icon }} menu-icon"></i>
              <ion-label>
                {{ page.menutitle }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
