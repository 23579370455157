import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StateService } from '../../../services/state.service';
import { RouterLink } from '@angular/router';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-settings-language',
  templateUrl: './settings-language.component.html',
  styleUrls: ['./settings-language.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    TranslateModule,
    IonHeader,
    IonButtons,
    IonToolbar,
    IonBackButton,
    IonTitle,
    IonContent,
    IonList,
    IonRadioGroup,
    IonItem,
    IonRadio,
    IonRouterLink,
  ],
})
export class SettingsLanguageComponent implements OnInit {
  currentLanguage: string | undefined = undefined;

  constructor(private stateService: StateService) {}

  ngOnInit() {
    this.stateService.currentLanguage$.subscribe((currentLanguage) => {
      this.currentLanguage = currentLanguage;
    });
  }

  setLanguage(event: CustomEvent) {
    this.stateService.setCurrentLanguage(event.detail.value);
  }
}
