import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from './window.service';
export const DEFAULT_LANGUAGE = 'da';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private translate: TranslateService,
    private windowService: WindowService,
  ) {}

  getSystemLanguage() {
    const window = this.windowService.nativeWindow();
    switch (window?.location?.host) {
      case 'pregnantwithdiabetes.com':
        return 'en';
      case 'gravidmeddiabetes.dk':
        return 'da';
      case 'gravidmeddiabetes.se':
        return 'sv';
    }

    const browserCultureLang = this.translate.getBrowserCultureLang();
    if (browserCultureLang?.toLocaleLowerCase().match(/au/)) {
      return 'au';
    }
    const browserLang = this.translate.getBrowserLang();
    return browserLang?.toLocaleLowerCase().match(/en|da|sv/) ? browserLang : DEFAULT_LANGUAGE;
  }
}
