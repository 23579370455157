import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Platform } from '@ionic/angular/standalone';
import { firstValueFrom } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { StateService } from 'src/app/services/state.service';
import { combinedCheckerHelper } from '../helpers/storage-checker.helper';
import { isNoMonitorAndProd } from '../helpers/check-for-monitor-and-prod.helper';
import { captureException } from '../helpers/error-capture.helper';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService {
  private id: string | null = null;
  private startAnalytics = false;
  constructor(
    private storageService: StorageService,
    private platform: Platform,
    private stateService: StateService,
  ) {}

  async init() {
    try {
      const shouldAnalyticsStart = isNoMonitorAndProd();
      await this.platform.ready();
      const platforms = this.platform.platforms().join('-');
      // if not a mobile app
      if (!platforms.includes('capacitor')) {
        if (!combinedCheckerHelper()) {
          this.startAnalytics = false;
          return;
        }
        await FirebaseAnalytics.initializeFirebase({
          apiKey: 'AIzaSyB0Mpzbkqb0c1Wy622lw3A_E49j0avFF8k',
          authDomain: 'pregnant-with-diabetes.firebaseapp.com',
          projectId: 'pregnant-with-diabetes',
          storageBucket: 'pregnant-with-diabetes.appspot.com',
          messagingSenderId: '392075839540',
          appId: '1:392075839540:web:c220f23d21fb625a49a261',
          measurementId: 'G-BLNEVPF0B0',
        });
      }
      await FirebaseAnalytics.setCollectionEnabled({
        enabled: shouldAnalyticsStart,
      });

      if (!shouldAnalyticsStart) {
        return;
      }

      this.id = await firstValueFrom(this.storageService.get$('app-id'));
      if (!this.id) {
        this.id = uuidv4();
        this.storageService.set$('app-id', this.id);
      }
      await FirebaseAnalytics.setUserId({
        userId: this.id,
      });
      this.startAnalytics = shouldAnalyticsStart;
      const medicalTrail = this.stateService.getMedicalTrail();
      if (medicalTrail) {
        this.setUserProperty('medicalTrail', medicalTrail);
      }
    } catch (error) {
      captureException(error, 'error', 'analytics-init');
    }
  }

  pageInit(url: string, title: string) {
    if (!this.startAnalytics) {
      return;
    }
    try {
      FirebaseAnalytics.setScreenName({
        screenName: url,
        nameOverride: title?.substring(0, title.indexOf('-')),
      }).catch((error) => {
        captureException(error, 'error', 'analytics-page-init-catch');
      });
    } catch (error) {
      captureException(error, 'error', 'analytics-page-init');
    }
  }

  trackEvent(name: string, params: object) {
    if (!this.startAnalytics) {
      return;
    }
    const eventName = `preg_${name}`;
    try {
      FirebaseAnalytics.logEvent({
        name: eventName,
        params,
      }).catch((error) => {
        captureException(error, 'error', 'analytics-track-event-catch');
      });
    } catch (error) {
      captureException(error, 'error', 'analytics-track-event');
    }
  }

  setUserProperty(name: string, value: string) {
    if (!this.startAnalytics) {
      return;
    }
    try {
      FirebaseAnalytics.setUserProperty({
        name,
        value,
      }).catch((error) => {
        captureException(error, 'error', 'analytics-set-user-property-catch');
      });
    } catch (error) {
      captureException(error, 'error', 'analytics-set-user-property');
    }
  }
}
