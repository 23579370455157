<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button text="" [routerLink]="'/settings'"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'SETTINGS.TREATMENT_PLACE' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-radio-group (ionChange)="setTreatmentPlace($event)" [value]="currentTreatmentPlace">
      <ion-item>
        <ion-radio justify="start" labelPlacement="end" value="riget">{{ 'SETTINGS.RIGET' | translate }}</ion-radio>
      </ion-item>
      <ion-item>
        <ion-radio justify="start" labelPlacement="end" value="herning">{{ 'SETTINGS.HERNING' | translate }}</ion-radio>
      </ion-item>
      <ion-item>
        <ion-radio justify="start" labelPlacement="end" value="andet">{{ 'SETTINGS.ANDET' | translate }}</ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>
