<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      {{ title }}
    </ion-title>
    <ion-buttons slot="end">
      <i class="{{ icon }} header-icon"></i>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="page-content" [innerHTML]="content"></div>
</ion-content>
